import {
  Box,
  Drawer,
  Stack,
  Typography,
  TextField,
  Button,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Menu,
  MenuItem,
  Tooltip,
  CircularProgress,
} from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CloseIcon from '@mui/icons-material/Close'
import React, { useState, useEffect, useRef } from 'react'
import Message from '../testPromptMessage'
import { useDispatch, useSelector } from 'react-redux'
import SettingsIcon from '@mui/icons-material/Settings'
import { USEISMOBILE } from '../../../constants/Constants'
import { MemorySettingsPopover, SettingsButton } from './testPromptDrawerStyles'
import { chatWithPrompt } from '../../../redux/action/TestPromptActions'
import { useAuth } from '../../../AuthContext'
import { toast } from 'react-toastify'
import { TestVariables } from '../testVariables'
import Swal from 'sweetalert2'
import { useTheme } from '@emotion/react'
import './style.css'
import LoadingIndicator from './LoadingIndicator'
import axiosInstance from '../../../util/axios'
import { uploadFileEndpoint } from '../../../services/Api'
import { StyledDialog, StyledDialogContent } from '../../studioInput/studioInputStyles'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import InfoIcon from '@mui/icons-material/Info'

const TestPromptDrawer = ({ open, handleClose }) => {
  const [memoryType, setMemoryType] = useState('windowMemory')
  const [windowSize, setWindowSize] = useState(2)
  const [shot, setShot] = useState(-1)
  const [messages, setMessages] = useState([])
  const [input, setInput] = useState('')
  const [showMemorySettings, setShowMemorySettings] = useState(false)
  const [chatWithPromptLoading, setChatWithPromptLoading] = useState(false)
  const [variableOpen, setVariableOpen] = useState(false)
  const [chatSessionId, setChatSessionId] = useState('')
  const [imagePreviews, setImagePreviews] = useState([])
  const [attachmentAnchor, setAttachmentAnchor] = useState(null)
  const uploadImageRef = useRef(null)
  const [openLinkDialog, setOpenLinkDialog] = useState(false)
  const [imageLink, setImageLink] = useState('')
  const [linkPreviewError, setLinkPreviewError] = useState('')
  const [audioPreviews, setAudioPreviews] = useState([])
  const [playingAudio, setPlayingAudio] = useState(null)
  const uploadAudioRef = useRef(null)
  const audioRefs = useRef({})
  const [loadingFiles, setLoadingFiles] = useState([])

  const { selectedPrompt, promptVersions, selectedPromptVersion } = useSelector(
    (state) => state.UserProjects,
  )
  const { isImageSupport, isAudioSupport } = useSelector((state) => state.conversations)
  const Theme = useTheme()

  const messagesEndRef = useRef(null)
  const isMobile = USEISMOBILE()

  const { userInfo } = useAuth()
  const dispatch = useDispatch()

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const { variables, selectedModel } = useSelector((state) => state.conversations)

  const [lclVariables, setLclVariables] = useState(variables)

  useEffect(() => {
    if (memoryType !== 'windowMemory') {
      setWindowSize('5')
    }
  }, [memoryType])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    if (chatSessionId === '') {
      setMessages([])
    }
  }, [chatSessionId])

  useEffect(() => {
    if (selectedModel !== 'claudeai') {
      setMemoryType('windowMemory')
    } else {
      setMemoryType('fullMemory')
    }
  }, [selectedModel])

  const handleSend = () => {
    if (input.trim() || imagePreviews.length > 0 || audioPreviews.length > 0) {
      let content = []

      if (imagePreviews.length > 0) {
        content = [
          ...content,
          ...imagePreviews.map((imageUrl) => ({
            type: 'file',
            file_url: { url: imageUrl },
          })),
        ]
      }

      if (audioPreviews.length > 0) {
        content = [
          ...content,
          ...audioPreviews.map((audio) => ({
            type: 'file',
            file_url: { url: audio.url },
          })),
        ]
      }

      if (input.trim()) {
        content.push({ type: 'text', text: input.trim() })
      }

      setMessages([...messages, { text: content, sender: 'user' }])

      chatWithPromptFn({
        ...chatData,
        user_message: content,
      })

      setInput('')
      setImagePreviews([])
      setAudioPreviews([])
    }
  }

  const requestPayloadFormat = (variables) => {
    return variables.reduce((acc, variable) => {
      acc[variable.key] = variable.value
      return acc
    }, {})
  }

  const chatData = {
    memory_type: memoryType,
    window_size: windowSize,
    shot: shot === '' ? -1 : shot,
    user_message: input,
    session_id: chatSessionId,
    request_from: 'prompt studio',
    variables: requestPayloadFormat(lclVariables.length > 0 ? lclVariables : variables),
  }

  const chatWithPromptFn = (chatData) => {
    setChatWithPromptLoading(true)
    try {
      dispatch(
        chatWithPrompt({
          userId: userInfo?.userId,
          versionId: selectedPromptVersion,
          chatData,
          meta: {
            onSuccess: (data) => {
              setMessages((prev) => [
                ...prev,
                { text: data?.data?.result?.response, sender: 'bot' },
              ])
              setChatSessionId(data?.data?.result?.session_id)
              setChatWithPromptLoading(false)
            },
            onError: (error) => {
              setChatWithPromptLoading(false)
              toast.error(error.data.message)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during fetch:', error)
    }
  }

  const popoverRef = useRef(null)

  const handleVariableToggle = () => {
    setVariableOpen(!variableOpen)
  }

  const handleInputChange = (e) => {
    const sanitizedInput = e.target.value.replace(/[{}]/g, '')
    setInput(sanitizedInput)
  }

  const handleCloseConfirm = () => {
    setShowMemorySettings(false)
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will reset the conversation',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: Theme.palette.primary.main,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, reset it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        handleClose()
        setChatSessionId('')
        if (selectedModel !== 'claudeai') {
          setMemoryType('windowMemory')
        } else {
          setMemoryType('fullMemory')
        }
        setLclVariables([])
        setMessages([])
      }
    })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setShowMemorySettings(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popoverRef])

  const handleOpenAttachmentMenu = (event) => {
    setAttachmentAnchor(event.currentTarget)
  }

  const handleCloseAttachmentMenu = () => {
    setAttachmentAnchor(null)
  }

  const handleUploadClick = () => {
    handleCloseAttachmentMenu()
    uploadImageRef.current.click()
  }

  const imageUploadFn = async (files) => {
    const formData = new FormData()
    Array.from(files).forEach((file) => {
      formData.append('files', file)
    })

    try {
      const response = await axiosInstance.post(uploadFileEndpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      return response.data.data.uploaded_files.map((file) => file.file_url)
    } catch (error) {
      console.error('Error uploading images:', error)
      throw new Error('Failed to upload images')
    }
  }

  const handleUploadImage = async (event) => {
    const files = event.target.files
    const maxSize = 5 * 1024 * 1024
    let validFiles = []

    try {
      validFiles = Array.from(files).filter((file) => {
        const fileType = file.type.toLowerCase()
        if (!['image/jpeg', 'image/jpg', 'image/png', 'image/jfif'].includes(fileType)) {
          toast.warning(
            `File ${file.name} is not a supported image format. Supported formats are JPG, JPEG, and PNG.`,
          )
          return false
        }
        if (file.size > maxSize) {
          toast.warning(`File ${file.name} exceeds the maximum size of 5MB.`)
          return false
        }
        return true
      })

      const loadingPlaceholders = validFiles.map((file) => ({
        name: file.name,
        loading: true,
      }))
      setLoadingFiles((prev) => [...prev, ...loadingPlaceholders])

      const imageUrls = await imageUploadFn(validFiles)

      setLoadingFiles((prev) =>
        prev.filter((file) => !loadingPlaceholders.find((p) => p.name === file.name)),
      )

      if (imageUrls.length > 0) {
        setImagePreviews((prev) => [...prev, ...imageUrls])
      }
    } catch (error) {
      console.error('Error in image upload process:', error)
      setLoadingFiles((prev) =>
        prev.filter((file) => !validFiles.find((f) => f.name === file.name)),
      )
      toast.error('Failed to upload some images. Please try again.')
    } finally {
      event.target.value = ''
    }
  }

  const handleDeleteImage = (index) => {
    setImagePreviews((prev) => prev.filter((_, i) => i !== index))
  }

  const handleMemoryTypeChange = (newMemoryType) => {
    if (chatSessionId === '') {
      // If session ID is empty, change memory type without confirmation
      setMemoryType(newMemoryType)
      setMessages([])
      toast.success('Memory type updated')
    } else {
      // If there's an active session, show confirmation alert
      const modalKey = 'memoryTypeChangeWarning_doNotShow'
      const doNotShow = localStorage.getItem(modalKey)

      if (doNotShow === 'true') {
        applyMemoryTypeChange(newMemoryType)
        return
      }

      Swal.fire({
        title: 'Start New Session?',
        text: 'Changing memory type will start a new session. Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: Theme.palette.primary.main,
        cancelButtonColor: '#d33',
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Cancel',
        input: 'checkbox',
        inputValue: 0,
        inputPlaceholder: 'Do not show this warning again',
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value === 1) {
            localStorage.setItem(modalKey, 'true')
          }
          applyMemoryTypeChange(newMemoryType)
        }
      })
    }
  }

  const applyMemoryTypeChange = (newMemoryType) => {
    setMemoryType(newMemoryType)
    setChatSessionId('')
    setMessages([])
    toast.success('New session started with updated memory type')
  }

  const handleOpenLinkDialog = () => {
    setOpenLinkDialog(true)
    handleCloseAttachmentMenu()
  }

  const handleCloseLinkDialog = () => {
    setOpenLinkDialog(false)
    setImageLink('')
    setLinkPreviewError('')
  }

  const handleImageLinkChange = (event) => {
    const url = event.target.value
    setImageLink(url)

    if (url && !url.match(/^https?:\/\//)) {
      setLinkPreviewError('URL must start with http:// or https://')
    } else {
      setLinkPreviewError('')
    }
  }

  const validateImageLink = async (url) => {
    if (!url.match(/^https?:\/\//)) {
      return false
    }

    url = url.replace(/^@/, '')

    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'no-cors',
      })
      return true
    } catch (error) {
      console.error('Error validating image link:', error)
      return false
    }
  }

  const handleAddImageLink = async () => {
    if (!imageLink.match(/^https?:\/\//)) {
      setLinkPreviewError('URL must start with http:// or https://')
      return
    }

    if (await validateImageLink(imageLink)) {
      setImagePreviews((prevPreviews) => [...prevPreviews, imageLink])
      handleCloseLinkDialog()
    } else {
      setLinkPreviewError('Not a valid image link')
    }
  }

  const handleUploadAudioClick = () => {
    handleCloseAttachmentMenu()
    uploadAudioRef.current.click()
  }

  const handleUploadAudio = async (event) => {
    const files = event.target.files
    const maxSize = 10 * 1024 * 1024
    let validFiles = []

    try {
      validFiles = Array.from(files).filter((file) => {
        const fileType = file.type.toLowerCase()
        if (!['audio/mpeg', 'audio/wav', 'audio/ogg'].includes(fileType)) {
          toast.warning(
            `File ${file.name} is not a supported audio format. Supported formats are MP3, WAV, and OGG.`,
          )
          return false
        }
        if (file.size > maxSize) {
          toast.warning(`File ${file.name} exceeds the maximum size of 10MB.`)
          return false
        }
        return true
      })

      const loadingPlaceholders = validFiles.map((file) => ({
        name: file.name,
        loading: true,
        type: 'audio',
      }))
      setLoadingFiles((prev) => [...prev, ...loadingPlaceholders])

      const audioUrls = await imageUploadFn(validFiles)

      setLoadingFiles((prev) =>
        prev.filter((file) => !loadingPlaceholders.find((p) => p.name === file.name)),
      )

      if (audioUrls.length > 0) {
        const newAudioPreviews = audioUrls.map((url, index) => ({
          url,
          name: validFiles[index].name,
          size: formatFileSize(validFiles[index].size),
          duration: 'Loading...',
        }))
        setAudioPreviews((prev) => [...prev, ...newAudioPreviews])
      }
    } catch (error) {
      console.error('Error in audio upload process:', error)
      setLoadingFiles((prev) =>
        prev.filter((file) => !validFiles.find((f) => f.name === file.name)),
      )
      toast.error('Failed to upload some audio files. Please try again.')
    } finally {
      event.target.value = ''
    }
  }

  const handleDeleteAudio = (index) => {
    setAudioPreviews((prev) => prev.filter((_, i) => i !== index))
    if (playingAudio === index) {
      setPlayingAudio(null)
    }
  }

  const handlePlayPauseAudio = (index) => {
    const audio = audioRefs.current[index]
    if (!audio) {
      console.warn(`Audio element at index ${index} is not available`)
      return
    }

    if (playingAudio === index) {
      if (audio.paused) {
        audio.play().catch((error) => console.error('Error playing audio:', error))
      } else {
        audio.pause()
      }
    } else {
      if (playingAudio !== null && audioRefs.current[playingAudio]) {
        audioRefs.current[playingAudio].pause()
      }
      audio.play().catch((error) => console.error('Error playing audio:', error))
      setPlayingAudio(index)
    }
  }

  const formatFileSize = (bytes) => {
    if (bytes < 1024) return bytes + ' B'
    else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + ' KB'
    else return (bytes / 1048576).toFixed(1) + ' MB'
  }

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.floor(seconds % 60)
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
  }

  useEffect(() => {
    audioPreviews.forEach((audio, index) => {
      if (audio.duration === 'Loading...') {
        const audioElement = new Audio(audio.url)
        audioElement.onloadedmetadata = () => {
          setAudioPreviews((prev) =>
            prev.map((a, i) =>
              i === index ? { ...a, duration: formatDuration(audioElement.duration) } : a,
            ),
          )
        }
      }
    })
  }, [audioPreviews])

  const handleShotChange = (e) => {
    const value = e.target.value
    // Allow empty string or valid numbers >= -1
    if (value === '' || (!isNaN(value) && parseInt(value) >= -1)) {
      setShot(value === '' ? '' : parseInt(value))
    }
  }

  const handleMemorySettingsToggle = () => {
    if (!showMemorySettings && shot === '') {
      setShot(-1)
    }
    setShowMemorySettings(!showMemorySettings)
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      // onClose={handleClose}
      sx={{ '& .MuiPaper-root': { width: isMobile ? '100%' : '35vw' } }}
    >
      <Stack sx={{ height: '100%', p: 2, position: 'relative' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{selectedPrompt.promptName}</Typography>
          <Stack direction="row" spacing={1}>
            <TestVariables
              handleToggle={handleVariableToggle}
              variableOpen={variableOpen}
              lclVariables={lclVariables}
              setLclVariablesInital={setLclVariables}
            />
            <SettingsButton onClick={handleMemorySettingsToggle}>
              <SettingsIcon />
            </SettingsButton>
            <IconButton onClick={handleCloseConfirm} edge="end" aria-label="close">
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
          {showMemorySettings && (
            <MemorySettingsPopover ref={popoverRef}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                {/* <Typography variant="subtitle1">Memory Settings</Typography>
                <IconButton onClick={() => setShowMemorySettings(false)} size="small">
                  <CloseIcon fontSize="small" />
                </IconButton> */}
              </Box>
              <FormControl component="fieldset">
                <FormLabel component="legend">Memory Type</FormLabel>
                <RadioGroup
                  aria-label="memory-type"
                  name="memory-type"
                  value={memoryType}
                  onChange={(e) => handleMemoryTypeChange(e.target.value)}
                >
                  <FormControlLabel value="fullMemory" control={<Radio />} label="Full memory" />
                  {/* {selectedModel !== 'claudeai' && ( */}
                  <FormControlLabel
                    value="windowMemory"
                    control={<Radio />}
                    label="Window memory"
                  />
                  {/* )} */}
                  {memoryType === 'windowMemory' && (
                    <TextField
                      type="number"
                      value={windowSize}
                      onChange={(e) => setWindowSize(e.target.value)}
                      InputProps={{ inputProps: { min: 1 } }}
                      label="Window Size"
                      size="small"
                      sx={{ mt: 2, width: '165px' }}
                    />
                  )}
                  {/* {selectedModel !== 'gemini' && ( */}
                  <FormControlLabel value="summarizedMemory" control={<Radio />} label="Summary" />
                  {/* )} */}
                </RadioGroup>
                <Box sx={{ mt: 3 }}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <FormLabel component="legend">Shot Settings</FormLabel>
                    <Tooltip
                      title={
                        <div>
                          <strong>Shot Control Guide:</strong>
                          <br />
                          • shot: 0 - Uses no messages from the prompt (empty context)
                          <br />
                          • shot: 1 - Uses first turn (2 messages: 1 user + 1 assistant)
                          <br />
                          • shot: 2 - Uses first two turns (4 messages: 2 user + 2 assistant)
                          <br />• shot: -1 - Uses all messages from the prompt
                        </div>
                      }
                      arrow
                    >
                      <InfoIcon sx={{ fontSize: 16, color: 'action.active', cursor: 'help' }} />
                    </Tooltip>
                  </Stack>
                  <TextField
                    type="number"
                    value={shot}
                    onChange={handleShotChange}
                    InputProps={{
                      inputProps: {
                        min: -1,
                        step: 1,
                      },
                    }}
                    label="Shot Count"
                    size="small"
                    sx={{ mt: 1, width: '165px' }}
                  />
                </Box>
              </FormControl>
            </MemorySettingsPopover>
          )}
        </Stack>
        <Box
          sx={{ flexGrow: 1, overflowY: 'auto', mb: 2, pr: '10px' }}
          ref={(el) => {
            if (el) {
              el.scrollTop = el.scrollHeight
            }
          }}
        >
          {messages.map((message, index) => (
            <Message key={index} {...message} />
          ))}
          {chatWithPromptLoading && <LoadingIndicator />}
          <div ref={messagesEndRef} />
        </Box>
        <Stack
          direction="row"
          spacing={2}
          flexWrap="wrap"
          sx={{ mb: 2, maxHeight: '180px', overflowY: 'scroll' }}
        >
          {loadingFiles.map((file, index) => (
            <div key={`loading-${index}`} style={{ position: 'relative', display: 'inline-block' }}>
              <Box
                sx={{
                  width: '64px',
                  height: '64px',
                  padding: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: Theme.palette.secondary.main,
                  borderRadius: '8px',
                }}
              >
                <CircularProgress size={30} />
              </Box>
              <Typography
                variant="caption"
                noWrap
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  color: Theme.palette.textColor,
                  mt: 1,
                }}
              >
                {file.name}
              </Typography>
            </div>
          ))}

          {imagePreviews?.map((image, index) => (
            <Box
              key={index}
              sx={{ position: 'relative', width: '64px', height: '64px', padding: '4px' }}
            >
              <img
                src={image}
                alt={`Preview ${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '8px',
                }}
              />
              <IconButton
                onClick={() => handleDeleteImage(index)}
                sx={{ position: 'absolute', top: 0, right: 0, p: '4px' }}
              >
                <CloseIcon sx={{ fontSize: '16px' }} />
              </IconButton>
            </Box>
          ))}
        </Stack>
        <Stack direction="row" spacing={2} overflow={'auto'}>
          {audioPreviews.map((audio, index) => (
            <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
              <audio
                ref={(el) => {
                  if (el) audioRefs.current[index] = el
                }}
                src={audio.url}
                onEnded={() => setPlayingAudio(null)}
                style={{ display: 'none' }}
              />
              <Box
                sx={{
                  width: '200px',
                  height: '128px',
                  margin: '5px',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: Theme.palette.secondary.main,
                  borderRadius: '8px',
                }}
              >
                <Typography
                  variant="caption"
                  noWrap
                  sx={{ width: '100%', textAlign: 'center', color: Theme.palette.textColor }}
                >
                  {audio.name}
                </Typography>
                <IconButton onClick={() => handlePlayPauseAudio(index)}>
                  {playingAudio === index && !audioRefs.current[index]?.paused ? (
                    <PauseIcon sx={{ color: Theme.palette.primary.main }} />
                  ) : (
                    <PlayArrowIcon sx={{ color: Theme.palette.primary.main }} />
                  )}
                </IconButton>
                <Typography variant="caption" sx={{ color: Theme.palette.textColor }}>
                  {audio.size} | {audio.duration}
                </Typography>
              </Box>
              <IconButton
                onClick={() => handleDeleteAudio(index)}
                style={{ position: 'absolute', top: 4, right: 4 }}
              >
                <CloseIcon sx={{ fontSize: '16px', color: Theme.palette.primary.main }} />
              </IconButton>
            </div>
          ))}
        </Stack>
        <Stack direction="row" spacing={1}>
          <TextField
            sx={{
              '& .MuiInputBase-root': {
                borderRadius: '8px',
                height: 40,
              },
            }}
            fullWidth
            value={input}
            onChange={(e) => handleInputChange(e)}
            placeholder="Type your message..."
            onKeyPress={(e) => !chatWithPromptLoading && e.key === 'Enter' && handleSend()}
          />
          {(isImageSupport || isAudioSupport) && (
            <Tooltip title="Attach File">
              <IconButton onClick={handleOpenAttachmentMenu}>
                <AttachFileIcon />
              </IconButton>
            </Tooltip>
          )}

          <Button
            variant="contained"
            onClick={handleSend}
            sx={{ borderRadius: '8px' }}
            disabled={chatWithPromptLoading}
          >
            Send
          </Button>
        </Stack>
      </Stack>
      <Menu
        anchorEl={attachmentAnchor}
        open={Boolean(attachmentAnchor)}
        onClose={handleCloseAttachmentMenu}
      >
        <MenuItem onClick={handleUploadClick}>Upload Image</MenuItem>
        {isAudioSupport && <MenuItem onClick={handleUploadAudioClick}>Upload Audio</MenuItem>}
        <MenuItem onClick={handleOpenLinkDialog}>Link to image</MenuItem>
      </Menu>
      <input
        multiple
        type="file"
        ref={uploadImageRef}
        accept="image/*"
        onChange={handleUploadImage}
        style={{ display: 'none' }}
      />
      <input
        multiple
        type="file"
        ref={uploadAudioRef}
        accept="audio/*"
        onChange={handleUploadAudio}
        style={{ display: 'none' }}
      />
      {/* Image Link Dialog */}
      <StyledDialog open={openLinkDialog} onClose={handleCloseLinkDialog}>
        <DialogTitle>Add Image Link</DialogTitle>
        <StyledDialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Image URL"
            type="url"
            fullWidth
            variant="outlined"
            value={imageLink}
            onChange={handleImageLinkChange}
            error={!!linkPreviewError}
            helperText={linkPreviewError}
            placeholder="https://example.com/image.jpg"
          />
          {imageLink && !linkPreviewError && (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <img
                src={imageLink}
                alt="Preview"
                style={{
                  maxWidth: '100%',
                  maxHeight: '200px',
                  objectFit: 'contain',
                  borderRadius: '8px',
                }}
                onError={() => setLinkPreviewError('Not a valid image link')}
              />
            </Box>
          )}
        </StyledDialogContent>
        <DialogActions>
          <Button onClick={handleCloseLinkDialog}>Cancel</Button>
          <Button onClick={handleAddImageLink} disabled={!!linkPreviewError}>
            Add
          </Button>
        </DialogActions>
      </StyledDialog>
    </Drawer>
  )
}

export default TestPromptDrawer
