import React, { useRef, useState, useMemo } from 'react'
import { Box, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material'
import { MessageBox, MessageTypography, NameTypography } from './MessageStyles'
import { Images } from '../../assets/image'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteMessage,
  editMessage,
  deleteMessageCompare,
  editMessageCompare,
} from '../../redux/slice/Conversation'
import CancelIcon from '@mui/icons-material/Cancel'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import './MarkdownStyles.css' // Import the CSS file

import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  List,
  Code,
  Heading,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  TodoList,
  Indent,
  Link,
  BlockQuote,
  Table,
  CodeBlock,
  FontColor,
  FontBackgroundColor,
  RemoveFormat,
  SourceEditing,
  Markdown,
} from 'ckeditor5'

import 'ckeditor5/ckeditor5.css'

import AceEditor from 'react-ace'

import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-monokai'

const isJsonString = (str) => {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

const getFileType = (url) => {
  const extension = url.split('.').pop().toLowerCase()
  if (['mp3', 'wav', 'ogg'].includes(extension)) {
    return 'audio'
  } else if (['jpg', 'jpeg', 'png', 'webp', 'jfif'].includes(extension)) {
    return 'image'
  }
  return 'unknown'
}

const Message = ({
  role,
  message,
  userInfo,
  loading,
  model,
  index,
  id,
  compare,
  isPublished,
  isFirst,
  prevMsgId,
  nextMsgId,
}) => {
  const { t, ready } = useTranslation()
  const isUser = role === 'USER'
  const modelImages = {
    claudeai: Images.claudeLogo,
    openai: Images.opeanAiLogo,
    gemini: Images.geminiLogo,
  }

  const theme = useTheme()
  const imageSrc = model && modelImages[model]
  const dispatch = useDispatch()
  const textFieldRef = useRef(null)

  const [hover, setHover] = useState(false)

  const [deleteHover, setDeleteHover] = useState(false)

  const { selectedModel, selectedModelCompare } = useSelector((state) => state.conversations)

  const [showEditor, setShowEditor] = useState(false)
  const [playingAudio, setPlayingAudio] = useState(null)
  const audioRefs = useRef({})

  const handleMessageBoxClick = () => {
    if (textFieldRef.current) {
      textFieldRef.current.focus()
    }
  }

  const Theme = useTheme()

  const handleDelete = (otherId) => {
    if (compare) {
      dispatch(deleteMessageCompare(id))
      if (selectedModelCompare === 'claudeai') {
        dispatch(deleteMessageCompare(otherId))
      }
    } else {
      dispatch(deleteMessage(id))
      if (selectedModel === 'claudeai') {
        dispatch(deleteMessage(otherId))
      }
    }
  }

  const handleEdit = (updatedContent) => {
    const newContent = message?.map((item) => {
      if (item.type === 'text') {
        return { ...item, text: updatedContent }
      }
      return item
    })

    if (compare) {
      dispatch(editMessageCompare({ id, updatedMessage: newContent }))
    } else {
      dispatch(editMessage({ id, updatedMessage: newContent }))
    }
  }

  const isJsonContent = useMemo(() => {
    try {
      if (typeof message[0].text === 'object') return true
      if (typeof message[0].text === 'string') {
        try {
          JSON.parse(message[0].text)
          return true
        } catch (e) {
          return false
        }
      }
    } catch (e) {
      return false
    }
    return false
  }, [message])

  const handlePlayPauseAudio = (url) => {
    const audio = audioRefs.current[url]
    if (!audio) return

    if (playingAudio === url) {
      if (audio.paused) {
        audio.play()
      } else {
        audio.pause()
      }
    } else {
      if (playingAudio) {
        audioRefs.current[playingAudio].pause()
      }
      audio.play()
      setPlayingAudio(url)
    }
  }

  const renderContent = () => {
    if (showEditor) {
      let editorData = ''
      if (isJsonContent) {
        if (typeof message[0].text === 'string') {
          try {
            const parsedJson = JSON.parse(message[0].text)
            editorData = '```json\n' + JSON.stringify(parsedJson, null, 2) + '\n```'
          } catch (e) {
            editorData = '```json\n' + message[0].text + '\n```'
          }
        } else {
          editorData = '```json\n' + JSON.stringify(message[0].text, null, 2) + '\n```'
        }
      } else {
        editorData = message?.find((item) => item.type === 'text')?.text || ''
      }
      return (
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: {
              items: [
                'markdown',
                'undo',
                'redo',
                '|',
                'heading',
                '|',
                'bold',
                'italic',
                'strikethrough',
                '|',
                'bulletedList',
                'numberedList',
                'todoList',
                '|',
                'outdent',
                'indent',
                '|',
                'link',
                'blockQuote',
                // 'insertTable',
                'codeBlock',
                'removeFormat',
                'sourceEditing',
              ],
            },
            plugins: [
              Bold,
              Essentials,
              Italic,
              Mention,
              Paragraph,
              Undo,
              List,
              Code,
              Heading,
              Underline,
              Strikethrough,
              Subscript,
              Superscript,
              TodoList,
              Indent,
              Link,
              BlockQuote,
              Table,
              CodeBlock,
              FontColor,
              FontBackgroundColor,
              RemoveFormat,
              SourceEditing,
              Markdown,
            ],
            licenseKey: '<YOUR_LICENSE_KEY>',
            mention: {
              // Mention configuration
            },
            sourceEditing: {
              allowedTags: ['*'], // Allow all HTML tags
            },
            codeBlock: {
              languages: [{ language: 'json', label: 'JSON' }],
            },
          }}
          data={editorData}
          onChange={(event, editor) => {
            const data = editor.getData()
            handleEdit(data)
          }}
          disabled={isPublished}
        />
      )
    } else {
      return message.map((item, index) => {
        if (item.type === 'text') {
          let contentToRender = item.text
          if (isJsonContent) {
            if (typeof item.text === 'string') {
              try {
                const parsedJson = JSON.parse(item.text)
                contentToRender = '```json\n' + JSON.stringify(parsedJson, null, 2) + '\n```'
              } catch (e) {
                contentToRender = '```json\n' + item.text + '\n```'
              }
            } else {
              contentToRender = '```json\n' + JSON.stringify(item.text, null, 2) + '\n```'
            }
          }
          return (
            <Box
              key={index}
              className="markdown-body"
              sx={{ wordWrap: 'break-word', overflowWrap: 'break-word', overflow: 'auto' }}
            >
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{contentToRender}</ReactMarkdown>
            </Box>
          )
        } else if (item.type === 'file') {
          const url = item.file_url.url
          const fileType = getFileType(url)

          if (fileType === 'image') {
            return (
              <img
                key={index}
                src={url}
                alt="User uploaded"
                style={{ maxWidth: '100%', maxHeight: '300px', marginTop: '10px' }}
              />
            )
          } else if (fileType === 'audio') {
            return (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <audio
                  ref={(el) => (audioRefs.current[url] = el)}
                  src={url}
                  onEnded={() => setPlayingAudio(null)}
                  style={{ display: 'none' }}
                />
                <IconButton onClick={() => handlePlayPauseAudio(url)}>
                  {playingAudio === url && !audioRefs.current[url]?.paused ? (
                    <PauseIcon sx={{ color: theme.palette.primary.main }} />
                  ) : (
                    <PlayArrowIcon sx={{ color: theme.palette.primary.main }} />
                  )}
                </IconButton>
                <Typography variant="caption" sx={{ marginLeft: '10px' }}>
                  {url.split('/').pop()} {/* Display filename */}
                </Typography>
              </Box>
            )
          }
        }
        return null
      })
    }
  }

  const calculateEditorHeight = (jsonString) => {
    const lineCount = jsonString.split('\n').length
    const lineHeight = 20 // Adjust this value based on your font size
    return Math.max(lineCount * lineHeight, 100) // Minimum height of 100px
  }

  const renderUserContent = () => {
    const textContent = message.find((item) => item.type === 'text')
    const imageContent = message.filter(
      (item) => item.type === 'file' && getFileType(item.file_url.url) === 'image',
    )
    const audioContent = message.filter(
      (item) => item.type === 'file' && getFileType(item.file_url.url) === 'audio',
    )

    return (
      <>
        {textContent && (
          <TextField
            fullWidth
            inputRef={textFieldRef}
            sx={{ width: '100%' }}
            variant="standard"
            value={
              typeof textContent.text === 'string'
                ? textContent.text
                : JSON.stringify(textContent.text, null, 2)
            }
            multiline
            InputProps={{
              disableUnderline: true,
            }}
            onChange={(e) => {
              handleEdit(e.target.value)
            }}
          />
        )}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
          {imageContent.map((item, index) => (
            <Box
              key={`image-${index}`}
              component="img"
              src={item.file_url.url}
              alt="User uploaded"
              sx={{
                width: '80px',
                height: '80px',
                objectFit: 'cover',
                borderRadius: '8px',
                '&&': {
                  // Double ampersand to increase specificity
                  borderRadius: '8px',
                  width: '80px',
                  height: '80px',
                },
              }}
            />
          ))}
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
          {audioContent.map((item, index) => (
            <Box
              key={`audio-${index}`}
              sx={{
                display: 'flex',
                alignItems: 'center',
                bgcolor: 'background.paper',
                border: 1,
                borderColor: 'divider',
                borderRadius: '16px',
                px: 1,
                pr: 2,
                py: 0.5,
              }}
            >
              <audio
                ref={(el) => (audioRefs.current[item.file_url.url] = el)}
                src={item.file_url.url}
                onEnded={() => setPlayingAudio(null)}
                style={{ display: 'none' }}
              />
              <IconButton size="small" onClick={() => handlePlayPauseAudio(item.file_url.url)}>
                {playingAudio === item.file_url.url &&
                !audioRefs.current[item.file_url.url]?.paused ? (
                  <PauseIcon fontSize="small" sx={{ color: theme.palette.primary.main }} />
                ) : (
                  <PlayArrowIcon fontSize="small" sx={{ color: theme.palette.primary.main }} />
                )}
              </IconButton>
              <Typography variant="caption" sx={{ ml: 1 }}>
                {item.file_url.url.split('/').pop()}
              </Typography>
            </Box>
          ))}
        </Box>
      </>
    )
  }

  return (
    <>
      <MessageBox
        assistant={!isUser}
        onClick={handleMessageBoxClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          pointerEvents: isPublished ? 'none' : 'auto',
          opacity: isPublished ? 0.7 : 1,
        }}
        deleteHover={deleteHover}
      >
        {isUser ? (
          <Stack
            direction="row"
            spacing={1}
            alignItems="flex-start"
            sx={{ width: '100%', justifyContent: 'center' }}
          >
            <img
              src={userInfo?.userProfile}
              alt="Profile"
              style={{ width: 40, height: 40, borderRadius: '8px', marginRight: 15 }}
            />
            <Box flexGrow={1}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <NameTypography>{userInfo?.userName}</NameTypography>
                <Box
                  sx={{ display: 'flex', justifyContent: 'flex-end', height: '30px', my: '10px' }}
                >
                  {hover && (
                    <IconButton onClick={() => handleDelete(nextMsgId)}>
                      <CancelIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
              {renderUserContent()}
            </Box>
          </Stack>
        ) : (
          <Stack
            direction="row"
            spacing={1}
            alignItems="flex-start"
            sx={{ width: '100%', justifyContent: 'center' }}
          >
            <img
              src={theme.palette.mode === 'light' ? Images.logo : Images.whiteLogo}
              alt="Profile"
              style={{
                width: 40,
                height: 25,
                borderRadius: '50%',
                marginRight: 15,
              }}
            />
            <Box flexGrow={1} sx={{ overflow: 'hidden' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <NameTypography>
                  {ready ? t('studio.studioInput.assistance') : 'Assistant'}
                </NameTypography>
                <Box
                  sx={{ display: 'flex', justifyContent: 'flex-end', height: '30px', my: '10px' }}
                >
                  {hover && (
                    <>
                      <IconButton onClick={() => setShowEditor(!showEditor)} sx={{ mr: 1 }}>
                        {showEditor ? <VisibilityIcon /> : <EditIcon />}
                      </IconButton>
                      <IconButton onClick={() => handleDelete(prevMsgId)}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Box>
              {renderContent()}
            </Box>
          </Stack>
        )}
      </MessageBox>
    </>
  )
}

export default Message
