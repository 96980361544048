import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  chatGPTKey: '',
  claudeKey: '',
  geminiKey: '',
  groqKey: '',
  grokKey: '',
  deepseekKey: '',
}

const userSettings = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    setChatGPTKey: (state, action) => {
      state.chatGPTKey = action.payload
    },
    setClaudeKey: (state, action) => {
      state.claudeKey = action.payload
    },
    setGeminiKey: (state, action) => {
      state.geminiKey = action.payload
    },
    setGroqKey: (state, action) => {
      state.groqKey = action.payload
    },
    setGrokKey: (state, action) => {
      state.grokKey = action.payload
    },
    setDeepseekKey: (state, action) => {
      state.deepseekKey = action.payload
    },
  },
})

export const { setChatGPTKey, setClaudeKey, setGeminiKey, setGroqKey, setGrokKey, setDeepseekKey } =
  userSettings.actions

export default userSettings.reducer
