import React, { useEffect, useState } from 'react'

import { CenterMainbox } from './studioViewStyles'
import HistoryModal from '../modal/historyModal'
import { AI_MODELS, MODEL_OPTIONS } from '../../constants/Constants'
import { useAuth } from '../../AuthContext'
import { useDispatch, useSelector } from 'react-redux'
import {
  setIsCompareMode,
  setIsPublished,
  setMessagesCompareInitial,
  setSelectedAi,
  setSelectedAiCompare,
  setSystemValueCompare,
  setVariableCompareKey,
  setVariableKey,
  setVariables,
} from '../../redux/slice/Conversation'
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom'
import { publishPrompt } from '../../redux/action/PlaygroundAction'
import { toast } from 'react-toastify'
import { StudioChat } from '../studioChat'
import { StudioInput } from '../studioInput'
import { StudioToolbar } from '../studioToolbar'
import WelcomeDialog from '../dialog/welcomeDialog'
import { v4 as uuidv4 } from 'uuid'
import { Box, Stack, Typography } from '@mui/material'
import { Button } from 'react-scroll'
import CommonStyles from '../../assets/styles/CommonStyles'
import TestPromptDrawer from '../testPrompt/testPromptDrawer'

const StudioView = ({
  showSecond,
  show,
  handleRightSidebar,
  handleRightSidebarCompare,
  copy = false,
  HandleClose,
  HandleWritten,
  component,
  selectedModelOptions,
  setSelectedModelOptions,
  selectedCategory,
  setSelectedCategory,
  selectedModelOptionsCompare,
  setSelectedModelOptionsCompare,
  selectedCategoryCompare,
  setSelectedCategoryCompare,
  HandleCopyRightClick,
  HandleCopyLeftClick,
  handleToggle1,
  handleToggle2,
  toggleFirst,
  toggleSecond,
  handleSaveOnCheckout,
}) => {
  const dispatch = useDispatch()

  const [showCompare, setShowCompare] = useState(false)
  const [modalOpen, setMOdalOpen] = useState(false)
  const [publishLoading, setPublishLoading] = useState(false)

  const [runLoading, setRunLoading] = useState(false)
  const [runLoadingCompare, setRunLoadingCompare] = useState(false)

  const {
    selectedModelCompare,
    selectedModel,
    messages,
    systemValue,
    selectedAi,
    parameters,
    variables,
    variableKey,
    isCompareMode,
    isPublished,
  } = useSelector((state) => state.conversations)

  const { userInfo } = useAuth()
  const location = useLocation()

  //variable handling

  const [variableName, setVariableName] = useState('')
  const [variableValue, setVariableValue] = useState('')
  const [selectedVariables, setSelectedVariables] = useState([])
  const [variableDialogueOpen, setVariableDialogueOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [testPromptOpen, setTestPromptOpen] = useState(false)
  const [chatSessionId, setChatSessionId] = useState('')

  const [variableOpen, setVariableOpen] = useState(false)
  const handleToggle = () => {
    setVariableOpen(!variableOpen)
  }

  const handleOpenDialog = () => setVariableDialogueOpen(true)
  const handleCloseDialog = () => setVariableDialogueOpen(false)

  const captureVariables = (text, component) => {
    const regex = /{{([^}]+)}}/g
    // const regex = /{([^}]+)}/g
    let match = text.match(regex) || []
    const matchArray = match.map((item) => item.slice(2, -2))
    const uniqueValues = [...new Set(matchArray)]
    component === 2
      ? dispatch(setVariableCompareKey(uniqueValues))
      : dispatch(setVariableKey(uniqueValues))
  }

  const { selectedProjectId, selectedPrompt } = useSelector((state) => state.UserProjects)

  const handleCompareClick = () => {
    dispatch(setIsCompareMode(true))
    showSecond(true)
    setShowCompare(true)
    // dispatch(setMessagesCompareInitial([]))
    // dispatch(setSystemValueCompare(''))
  }

  const handleCompareClose = () => {
    dispatch(setIsCompareMode(false))
    HandleClose()
    showSecond(false)
    HandleWritten(false)
    // dispatch(setMessagesCompareInitial([]))
    // dispatch(setSystemValueCompare(''))
  }

  const handleModalClose = () => {
    setMOdalOpen(false)
  }
  const publishPromptFn = (publishObject) => {
    setPublishLoading(true)
    try {
      dispatch(
        publishPrompt({
          userId: userInfo?.userId,
          publishObject,
          meta: {
            onSuccess: (data) => {
              dispatch(setIsPublished(true))
              toast.success(data[0].detail)
              setPublishLoading(false)
            },
            onError: (error) => {
              toast.error(error.data.detail)
              setPublishLoading(false)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during fetch:', error)
    }
  }

  const handlePublishClick = () => {
    const { temperature, maxTokens, topP, frequencyPenalty, presencePenalty } =
      parameters[selectedModel]
    const publishObject = {
      messages: messages,
      platform: selectedModel,
      model: selectedCategory,
      temperature: temperature !== undefined && temperature !== null ? temperature : 0,
      max_tokens: maxTokens !== undefined && maxTokens !== null ? maxTokens : 0,
      top_p: topP !== undefined && topP !== null ? topP : 0,
      frequency_Penalty:
        frequencyPenalty !== undefined && frequencyPenalty !== null ? frequencyPenalty : 0,
      presence_Penalty:
        presencePenalty !== undefined && presencePenalty !== null ? presencePenalty : 0,
      system_message: systemValue,
      variables: {
        variable1: ['1', '12', '123'],
        variable2: 'value2',
      },
      status: 'inactive',
      path: `${selectedProjectId}/${selectedPrompt.promptFolderId}/${selectedPrompt.promptId}`,
    }
    publishPromptFn(publishObject)
  }

  const updateSelectedModel = (model) => {
    if (component === 2) {
      dispatch(setSelectedAiCompare(model))
    } else {
      dispatch(setSelectedAi(model))
    }
    // if (checkpresent(model)) {
    // dispatch(setSelectedAi(model))
    // } else {
    // configurationErrorAlert(model)
    // }
  }

  const handleChatClick = () => {
    setTestPromptOpen(true)
    // setChatSessionId(uuidv4())
  }

  const handleChatClose = () => {
    setTestPromptOpen(false)
    setChatSessionId('')
  }

  useEffect(() => {
    Swal.close()
  }, [location])

  return (
    <>
      <CenterMainbox>
        {/* Top Part: Dropdowns, Publish Button, etc. */}
        <StudioToolbar
          component={component}
          selectedModelOptionsCompare={selectedModelOptionsCompare}
          selectedModelOptions={selectedModelOptions}
          selectedCategoryCompare={selectedCategoryCompare}
          selectedCategory={selectedCategory}
          setSelectedCategoryCompare={setSelectedCategoryCompare}
          setSelectedCategory={setSelectedCategory}
          handleRightSidebar={handleRightSidebar}
          handleRightSidebarCompare={handleRightSidebarCompare}
          handlePublishClick={handlePublishClick}
          show={show}
          handleCompareClick={handleCompareClick}
          handleCompareClose={handleCompareClose}
          HandleCopyRightClick={HandleCopyRightClick}
          HandleCopyLeftClick={HandleCopyLeftClick}
          handleToggle1={handleToggle1}
          handleToggle2={handleToggle2}
          toggleFirst={toggleFirst}
          toggleSecond={toggleSecond}
          variables={variables}
          captureVariables={captureVariables}
          publishLoading={publishLoading}
          setSelectedModelOptions={setSelectedModelOptions}
          handleSaveOnCheckout={handleSaveOnCheckout}
          variableOpen={variableOpen}
          handleToggle={handleToggle}
          isCompareMode={isCompareMode}
        />

        {/* Middle Chat Part: System and Messages */}
        <StudioChat
          component={component}
          show={show}
          handleOpenDialog={handleOpenDialog}
          variableDialogueOpen={variableDialogueOpen}
          handleCloseDialog={handleCloseDialog}
          variableName={variableName}
          setVariableName={setVariableName}
          variableValue={variableValue}
          setVariableValue={setVariableValue}
          selectedVariables={selectedVariables}
          isEditing={isEditing}
          captureVariables={captureVariables}
          runLoading={runLoading}
          setRunLoading={setRunLoading}
          runLoadingCompare={runLoadingCompare}
          setRunLoadingCompare={setRunLoadingCompare}
        />

        {/* Bottom Part: User Input Text Field and Buttons */}
        {component === 1 ? (
          isPublished ? (
            <Stack width="100%" justifyContent="center" alignItems="center">
              <CommonStyles.PrimaryButton width="auto" onClick={handleChatClick}>
                Test {selectedPrompt.promptName}
              </CommonStyles.PrimaryButton>
            </Stack>
          ) : (
            <StudioInput
              component={component}
              selectedModelOptions={selectedModelOptions}
              selectedCategory={selectedCategory}
              selectedModelOptionsCompare={selectedModelOptionsCompare}
              selectedCategoryCompare={selectedCategoryCompare}
              variables={variables}
              captureVariables={captureVariables}
              variableOpen={variableOpen}
              handleToggle={handleToggle}
              runLoading={runLoading}
              setRunLoading={setRunLoading}
              runLoadingCompare={runLoadingCompare}
              setRunLoadingCompare={setRunLoadingCompare}
            />
          )
        ) : (
          <StudioInput
            component={component}
            selectedModelOptions={selectedModelOptions}
            selectedCategory={selectedCategory}
            selectedModelOptionsCompare={selectedModelOptionsCompare}
            selectedCategoryCompare={selectedCategoryCompare}
            variables={variables}
            captureVariables={captureVariables}
            variableOpen={variableOpen}
            handleToggle={handleToggle}
            runLoading={runLoading}
            setRunLoading={setRunLoading}
            runLoadingCompare={runLoadingCompare}
            setRunLoadingCompare={setRunLoadingCompare}
          />
        )}
      </CenterMainbox>

      <WelcomeDialog />
      <HistoryModal open={modalOpen} handleClose={handleModalClose} />
      <TestPromptDrawer
        open={testPromptOpen}
        // chatSessionId={chatSessionId}
        handleClose={handleChatClose}
      />
    </>
  )
}

export default StudioView
