import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/material'
import {
  CustomAccordionSummary,
  LoadingBox,
  MainBox,
  SystemAccordion,
  SystemAccordionDetails,
  SystemMessageBox,
  SystemPromptBox,
} from './studioChatStyles'

import Message from '../message/enhanced'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { setSystemValue, setSystemValueCompare } from '../../redux/slice/Conversation'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../../AuthContext'
import { useTranslation } from 'react-i18next'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  List,
  Code,
  Heading,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  TodoList,
  Indent,
  Link,
  BlockQuote,
  Table,
  CodeBlock,
  FontColor,
  FontBackgroundColor,
  RemoveFormat,
  SourceEditing,
  Markdown,
} from 'ckeditor5'

import 'ckeditor5/ckeditor5.css'
import TurndownService from 'turndown'

const LoadingDots = () => {
  const Theme = useTheme()

  return (
    <Box
      sx={{
        padding: '10px',
        backgroundColor: Theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography variant="body1" color="text.secondary" sx={{ mr: 1 }}>
        {/* thinking */}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', ml: 2 }}>
        {[0, 1, 2].map((index) => (
          <Box
            key={index}
            sx={{
              width: '5px',
              height: '5px',
              backgroundColor: Theme.palette.text.secondary,
              borderRadius: '50%',
              mx: '2px',
              animation: `wave 1.3s ease-in-out ${index * 0.1}s infinite`,
              '@keyframes wave': {
                '0%, 60%, 100%': {
                  transform: 'translateY(0)',
                },
                '30%': {
                  transform: 'translateY(-5px)',
                },
              },
            }}
          />
        ))}
      </Box>
    </Box>
  )
}

export const StudioChat = ({
  component,
  show,
  captureVariables,
  runLoading,
  runLoadingCompare,
}) => {
  const Theme = useTheme()
  const messageBoxRef = useRef(null)
  const dispatch = useDispatch()
  const { userInfo } = useAuth()
  const {
    messagesCompare,
    systemValueCompare,
    messages,
    systemValue,
    userValue,
    isLoading,
    isPublished,
    userValueCompare,
  } = useSelector((state) => state.conversations)
  const { t, ready } = useTranslation()

  const { selectedPrompt } = useSelector((state) => state.UserProjects)
  const { selectedModel, selectedModelCompare } = useSelector((state) => state.conversations)

  const [prevValue, setPrevValue] = useState('')
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true)

  const handleSystemChange = (event) => {
    const value = event.target.value
    let allMessage = ''
    component === 2
      ? messagesCompare.map((item) => {
          return (allMessage += item.content)
        })
      : messages.map((item) => {
          return (allMessage += item.content)
        })

    component === 2
      ? captureVariables(value + userValueCompare + allMessage, component)
      : captureVariables(value + userValue + allMessage, component)
    setPrevValue(value)
    if (component === 2) {
      dispatch(setSystemValueCompare(value))
    } else {
      dispatch(setSystemValue(value))
    }
  }

  const scrollToBottom = () => {
    if (messageBoxRef.current && shouldScrollToBottom) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages, show, messagesCompare, shouldScrollToBottom])

  useEffect(() => {
    const handleScroll = () => {
      if (messageBoxRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = messageBoxRef.current
        const isAtBottom = scrollHeight - scrollTop - clientHeight < 1
        setShouldScrollToBottom(isAtBottom)
      }
    }

    messageBoxRef.current?.addEventListener('scroll', handleScroll)
    return () => messageBoxRef.current?.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <MainBox ref={messageBoxRef}>
      <SystemPromptBox>
        <Box>
          <SystemMessageBox>
            <SystemAccordion>
              <CustomAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span> {ready ? t('studio.studioChat.system') : 'SYSTEM'}</span>
                </Box>
              </CustomAccordionSummary>

              <SystemAccordionDetails>
                <Stack direction="column" mt={0}>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      toolbar: {
                        items: [
                          'markdown',
                          'undo',
                          'redo',
                          '|',
                          'heading',
                          '|',
                          'bold',
                          'italic',
                          'strikethrough',
                          '|',
                          'bulletedList',
                          'numberedList',
                          'todoList',
                          '|',
                          'outdent',
                          'indent',
                          '|',
                          'link',
                          'blockQuote',
                          'insertTable',
                          'codeBlock',
                          'fontColor',
                          'removeFormat',
                          'sourceEditing',
                        ],
                      },
                      plugins: [
                        Bold,
                        Essentials,
                        Italic,
                        Mention,
                        Paragraph,
                        Undo,
                        List,
                        Code,
                        Heading,
                        Underline,
                        Strikethrough,
                        Subscript,
                        Superscript,
                        TodoList,
                        Indent,
                        Link,
                        BlockQuote,
                        Table,
                        CodeBlock,
                        FontColor,
                        FontBackgroundColor,
                        RemoveFormat,
                        SourceEditing,
                        Markdown,
                      ],
                      licenseKey: '<YOUR_LICENSE_KEY>',
                      mention: {
                        // Mention configuration
                      },
                      sourceEditing: {
                        allowedTags: ['*'], // Allow all HTML tags
                      },
                      codeBlock: {
                        languages: [
                          { language: 'json', label: 'JSON' },
                          { language: 'comment', label: 'Comment' },
                        ],
                      },
                    }}
                    onReady={(editor) => {
                      // Remove entire autoformat configuration
                    }}
                    data={component === 2 ? systemValueCompare : systemValue}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      handleSystemChange({ target: { value: data } })
                    }}
                    disabled={component === 2 ? false : isPublished}
                  />
                </Stack>
              </SystemAccordionDetails>
            </SystemAccordion>
          </SystemMessageBox>
          <Stack flex="1 1 auto">
            {isLoading ? (
              <LoadingBox>
                <CircularProgress />
              </LoadingBox>
            ) : component === 1 ? (
              <>
                {messages &&
                  messages?.map((msg, index) => (
                    <Box key={index}>
                      <Message
                        role={msg?.role?.toUpperCase()}
                        message={msg.content}
                        userInfo={userInfo}
                        id={msg._id}
                        index={index}
                        {...(msg.model && { model: msg.model })}
                        isPublished={isPublished}
                        isFirst={selectedModel === 'claudeai' && index === 0}
                        prevMsgId={index > 0 ? messages[index - 1]._id : null}
                        nextMsgId={index < messages.length - 1 ? messages[index + 1]._id : null}
                      />
                    </Box>
                  ))}
                {runLoading && <LoadingDots />}
              </>
            ) : (
              <>
                {messagesCompare &&
                  messagesCompare?.map((msg, index) => (
                    <Box key={index}>
                      <Message
                        role={msg?.role?.toUpperCase()}
                        message={msg.content}
                        userInfo={userInfo}
                        id={msg._id}
                        index={index}
                        compare={true}
                        {...(msg.model && { model: msg.model })}
                        isPublished={false}
                        isFirst={selectedModelCompare === 'claudeai' && index === 0}
                        prevMsgId={index > 0 ? messagesCompare[index - 1]._id : null}
                        nextMsgId={
                          index < messagesCompare.length - 1 ? messagesCompare[index + 1]._id : null
                        }
                      />
                    </Box>
                  ))}
                {runLoadingCompare && <LoadingDots />}
              </>
            )}
          </Stack>
        </Box>
      </SystemPromptBox>
    </MainBox>
  )
}
