import React, { useEffect, useRef, useState } from 'react'
import {
  MainBox,
  StyledDialog,
  StyledDialogContent,
  UserPromptBox,
  UserTextField,
} from './studioInputStyles'
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
  DialogTitle,
  DialogActions,
  TextField,
  CircularProgress,
} from '@mui/material'
import { Box, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAssistantValue,
  setMessages,
  setMessagesCompare,
  setUserValue,
  setUserValueCompare,
  setAssistantValueCompare,
} from '../../redux/slice/Conversation'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from '../../router/Navigation'
import { v4 as uuidv4 } from 'uuid'
import { chatWithModel } from '../../redux/action/PlaygroundAction'
import { toast } from 'react-toastify'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../AuthContext'
import CommonStyles from '../../assets/styles/CommonStyles'
import { checkMessageSequence, isAllVariablePresent, replaceVariables } from '../../util/helpers'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CloseIcon from '@mui/icons-material/Close'
import { MODEL_FEATURES, USEISMOBILE } from '../../constants/Constants'
import axiosInstance from '../../util/axios'
import { uploadFileEndpoint } from '../../services/Api'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import { Typography } from '@mui/material'

// Styled components for custom dialog

const removeCommentBlocks = (text) => {
  if (!text) return text
  // Removes all content between ```comment and ``` including the markers
  return text.replace(/```comment[\s\S]*?```/g, '')
}

export const StudioInput = ({
  component,
  selectedCategory,
  selectedCategoryCompare,
  variables,
  captureVariables,
  handleToggle,
  runLoading,
  setRunLoading,
  runLoadingCompare,
  setRunLoadingCompare,
}) => {
  const Theme = useTheme()
  const [role, setRole] = useState('user')

  const {
    selectedModel,
    systemValue,
    userValue,
    userValueCompare,
    messages,
    messagesCompare,
    isPublished,
    assistantValue,
    assistantValueCompare,
    responseFormat,
    responseFormatCompare,
    jsonMode,
    jsonModeCompare,
    parameters,
    parametersCompare,
  } = useSelector((state) => state.conversations)
  const {
    selectedProjectId,
    selectedFolderId,
    selectedPrompt,
    selectedPromptVersion,
    promptVersions,
  } = useSelector((state) => state.UserProjects)

  // const [runLoading, setRunLoading] = useState(false)
  const [imagePreviews, setImagePreviews] = useState([]) // State to hold image previews
  const [imageLink, setImageLink] = useState('')
  const [attachmentAnchor, setAttachmentAnchor] = useState(null)
  const uploadImageRef = useRef(null)

  const inputRef = useRef(null)

  // Speech to text implementation
  const { resetTranscript } = useSpeechRecognition()

  const {
    selectedModelCompare,
    systemValueCompare,
    variablesCompare,
    variableKeyCompare,
    variableKey,
  } = useSelector((state) => state.conversations)

  const { projectsData } = useSelector((state) => state.UserProjects)

  const [isMicProcessing, setIsMicProcessing] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t, ready } = useTranslation()
  const { userInfo } = useAuth()
  const userId = userInfo?.userId
  const ismobile = USEISMOBILE()

  const [openLinkDialog, setOpenLinkDialog] = useState(false)
  const [linkPreviewError, setLinkPreviewError] = useState('')

  const [audioPreviews, setAudioPreviews] = useState([])
  const [playingAudio, setPlayingAudio] = useState(null)
  const uploadAudioRef = useRef(null)
  const audioRefs = useRef({})

  const [loadingFiles, setLoadingFiles] = useState([])

  const handleRole = () => {
    if (component === 1) {
      if (selectedModel === 'claudeai') {
        return
      } else {
        setRole((prevRole) => (prevRole === 'user' ? 'assistant' : 'user'))
      }
    }
    if (component === 2) {
      if (selectedModelCompare === 'claudeai') {
        return
      } else {
        setRole((prevRole) => (prevRole === 'user' ? 'assistant' : 'user'))
      }
    }
  }

  const configurationErrorAlert = (model) => {
    Swal.fire({
      title: 'Configuration Error',
      text: 'API Key for ' + model + ' is not Configured',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: Theme.palette.primary.main,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Go to Settings',
      cancelButtonText: 'Close',
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToSettings()
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle exit button action, if any
      }
    })
  }
  const navigateToSettings = () => {
    navigate(navigationRoutes.user.settings)
  }

  const handleChange = (event) => {
    const value = event.target.value
    let allMessage = ''
    component === 2
      ? messagesCompare.map((item) => {
          return (allMessage += item.content)
        })
      : messages.map((item) => {
          return (allMessage += item.content)
        })
    component === 2
      ? captureVariables(systemValueCompare + value + allMessage, component)
      : captureVariables(systemValue + value + allMessage, component)

    // Handle role-based value assignmentssss
    if (role === 'assistant') {
      if (component === 1) {
        dispatch(setAssistantValue(value)) // Set normal assistant value
      } else {
        dispatch(setAssistantValueCompare(value)) // Handle comparison value for assistant
      }
    } else {
      // Handle user value assignment
      if (component === 1) {
        dispatch(setUserValue(value)) // Set normal user value
      } else {
        dispatch(setUserValueCompare(value)) // Handle comparison value for user
      }
    }
  }

  const setChatHistory = (role, value) => {
    let content = []

    // Add image and audio URLs to content only if the role is 'user'
    if (role === 'user') {
      ;[...imagePreviews, ...audioPreviews].forEach((item) => {
        content.push({
          type: 'file',
          file_url: { url: item.url || item },
        })
      })
    }

    // Add text content
    if (typeof value === 'string') {
      if (value.trim() !== '') {
        content.push({ type: 'text', text: value })
      }
    } else {
      content.push({ type: 'text', text: value })
    }

    // Only proceed with dispatch if there's content to send
    // For user messages: need either files or non-empty text
    // For assistant messages: always proceed
    if (role === 'user' && content.length === 0) {
      return null
    }

    let messages = {
      _id: uuidv4(),
      role: role,
      content: content,
      model: component === 2 ? selectedModelCompare : selectedModel,
      category: component === 2 ? selectedCategoryCompare : selectedCategory,
    }

    if (component === 2) {
      dispatch(setMessagesCompare(messages))
    } else if (component === 1) {
      dispatch(setMessages(messages))
    }

    // Clear image and audio previews after adding to chat history, but only for user messages
    if (role === 'user') {
      setImagePreviews([])
      setAudioPreviews([])
    }

    return messages
  }

  const handleAddComponent1 = () => {
    if (
      userValue.trim() === '' &&
      assistantValue.trim() === '' &&
      imagePreviews.length === 0 &&
      audioPreviews.length === 0
    ) {
      return
    }
    const currentValue = role === 'assistant' ? assistantValue : userValue
    setChatHistory(role, currentValue)
    SpeechRecognition.stopListening()
    resetTranscript()
    role === 'assistant' ? dispatch(setAssistantValue('')) : dispatch(setUserValue(''))
  }

  const handleAddComponent2 = () => {
    if (
      userValueCompare.trim() === '' &&
      assistantValueCompare.trim() === '' &&
      imagePreviews.length === 0 &&
      audioPreviews.length === 0
    ) {
      return
    }
    const currentValue = role === 'assistant' ? assistantValueCompare : userValueCompare
    setChatHistory(role, currentValue)
    SpeechRecognition.stopListening()
    resetTranscript()
    role === 'assistant'
      ? dispatch(setAssistantValueCompare(''))
      : dispatch(setUserValueCompare(''))
  }

  const handleAdd = () => {
    if (component === 1) {
      handleAddComponent1()
    } else if (component === 2) {
      handleAddComponent2()
    }
  }

  const checkImageSupport = (messages, selectedCategory) => {
    const hasMediaFiles = messages.some((message) =>
      message.content.some((item) => {
        if (item.type === 'file' || item.type === 'image') {
          const url = item.file_url?.url || item.file_url
          const extension = url.split('.').pop().toLowerCase()
          return ['jpg', 'jpeg', 'png', 'jfif', 'bmp', 'webp'].includes(extension)
        }
        return false
      }),
    )

    if (!hasMediaFiles) {
      return true // No images, so it's supported
    }

    // Check if the selected category supports images
    return MODEL_FEATURES[selectedCategory]?.image === true
  }

  const checkAudioSupport = (messages, selectedCategory) => {
    const hasAudioFiles = messages.some((message) =>
      message.content.some((item) => {
        if (item.type === 'file' || item.type === 'image') {
          const url = item.file_url?.url || item.file_url
          const extension = url.split('.').pop().toLowerCase()
          return ['mp3', 'wav', 'ogg', 'm4a', 'aac'].includes(extension)
        }
        return false
      }),
    )

    if (!hasAudioFiles) {
      return true // No audio files, so it's supported
    }

    // Check if the selected category supports audio
    return MODEL_FEATURES[selectedCategory]?.audio === true
  }

  const handleRun = (inputValue) => {
    // Common validations
    if (projectsData?.folders?.length === 0) {
      toast.warning('Please create a Folder first')
      return
    }

    if (!selectedPrompt?.promptId) {
      toast.warning('Please Select a Prompt first')
      return
    }

    if (!selectedPromptVersion) {
      toast.warning('Please Select a Version first')
      return
    }

    if (selectedModel === 'claudeai' && !checkMessageSequence(messages)) {
      toast.warning(
        'The messages are not in the expected sequence of User and Assistant interactions. Please ensure that the messages are in the correct order.',
      )
      return
    }

    const currentMessages = component === 2 ? messagesCompare : messages
    const lastMessage = currentMessages[currentMessages.length - 1]

    // If value is empty and last message was from user, return without adding new message
    // if (
    //   inputValue.trim() === '' &&
    //   lastMessage?.role === 'user' &&
    //   imagePreviews.length === 0 &&
    //   audioPreviews.length === 0
    // ) {
    //   return null
    // }

    if (
      !checkImageSupport(
        component === 2 ? messagesCompare : messages,
        component === 2 ? selectedCategoryCompare : selectedCategory,
      )
    ) {
      toast.warning(
        'Warning: The selected model does not support images, but your chat history includes images.',
      )
      return
    }

    if (
      !checkAudioSupport(
        component === 2 ? messagesCompare : messages,
        component === 2 ? selectedCategoryCompare : selectedCategory,
      )
    ) {
      toast.warning(
        'Warning: The selected model does not support audio, but your chat history includes audio.',
      )
      return
    }

    if (component === 1 && jsonMode && !responseFormat) {
      toast.warning('Please enter a valid JSON Schema')
      return
    }

    if (component === 2 && jsonModeCompare && !responseFormatCompare) {
      toast.warning('Please enter a valid JSON Schema')
      return
    }

    // Component-specific handling
    if (component === 2) {
      handleComponentTwo(inputValue)
    } else if (component === 1) {
      handleComponentOne(inputValue)
    }
  }

  // Function to handle component 1 logic
  const handleComponentOne = (inputValue) => {
    const lastMessage = messages[messages.length - 1]
    // Check if input and assistant value are empty for component 1, and no images or audio are present
    if (
      inputValue.trim() === '' &&
      lastMessage?.role !== 'user' &&
      assistantValue.trim() === '' &&
      imagePreviews.length === 0 &&
      audioPreviews.length === 0
    ) {
      toast.warning('Please enter a message or attach a file')
      return
    }

    if (!isAllVariablePresent(variables, variableKey)) {
      toast.warning('Please fill all variable values')
      handleToggle()
      return
    }

    if (assistantValue) {
      setChatHistory('assistant', assistantValue)
      dispatch(setAssistantValue(''))
      return
    }
    dispatch(setUserValue(''))

    const currentValue = role === 'assistant' ? assistantValue : userValue
    processRun(role, currentValue, selectedModel, messages)
  }

  // Function to handle component 2 logic
  const handleComponentTwo = (inputValue) => {
    const lastMessage = messagesCompare[messagesCompare.length - 1]
    // Check if input and assistantValueCompare are empty for component 2, and no images are present
    if (
      inputValue.trim() === '' &&
      lastMessage?.role !== 'user' &&
      assistantValueCompare.trim() === '' &&
      imagePreviews.length === 0 &&
      audioPreviews.length === 0
    ) {
      toast.warning('Please enter a message or attach an image')
      return
    }

    if (!isAllVariablePresent(variablesCompare, variableKeyCompare)) {
      toast.warning('Please fill all variable values')
      handleToggle()
      return
    }

    if (assistantValueCompare) {
      setChatHistory('assistant', assistantValueCompare)
      dispatch(setAssistantValueCompare(''))
      return
    }
    dispatch(setUserValueCompare(''))

    const currentValue = role === 'assistant' ? assistantValueCompare : userValueCompare
    processRun(role, currentValue, selectedModelCompare, messagesCompare)
  }

  // Common function to process the run
  const processRun = (role, currentValue, selectedModel, messageList) => {
    const newMessage = setChatHistory(role, currentValue)
    const updatedMessages = newMessage ? [...messageList, newMessage] : messageList
    switch (selectedModel) {
      case 'openai':
      case 'groq':
      case 'grok':
      case 'deepseek':
        chatFn(updatedMessages, 'openai')
        break
      case 'claudeai':
        chatFn(updatedMessages, 'claude')
        break
      case 'gemini':
        chatFn(updatedMessages, 'gemini')
        break
      default:
        break
    }
  }

  const chatFn = (updatedMsg, platform) => {
    if (component === 1) {
      setRunLoading(true)
    }
    if (component === 2) {
      setRunLoadingCompare(true)
    }

    const replacedMessages = updatedMsg.map((message) => ({
      role: message.role,
      content: Array.isArray(message.content)
        ? message.content.map((item) => {
            if (item.type === 'text') {
              return {
                ...item,
                text: replaceVariables(item.text, variables),
              }
            }
            return item // Return image items unchanged
          })
        : replaceVariables(message.content, variables),
    }))

    const replacedMessagesCompare = updatedMsg.map((message) => ({
      role: message.role,
      content: Array.isArray(message.content)
        ? message.content.map((item) => {
            if (item.type === 'text') {
              return {
                ...item,
                text: replaceVariables(item.text, variablesCompare),
              }
            }
            return item // Return image items unchanged
          })
        : replaceVariables(message.content, variablesCompare),
    }))

    // const parserdFormat = responseFormat && JSON.parse(responseFormat)

    const payload = {
      messages: component === 2 ? replacedMessagesCompare : replacedMessages,
      ...((component === 2 ? selectedModelCompare : selectedModel) !== 'claudeai' && {
        user_message:
          component === 2
            ? replaceVariables(userValueCompare, variablesCompare)
            : replaceVariables(userValue, variables),
        assistant_message:
          component === 2
            ? replaceVariables(assistantValueCompare, variablesCompare)
            : replaceVariables(assistantValue, variables),
      }),
      projectId: selectedProjectId ?? '', // Add default or validation here if necessary
      folderId: selectedFolderId ?? '', // Same here for validation
      promptId: selectedPrompt?.promptId ?? '', // Check for valid promptId
      isPublished: isPublished ?? false, // Set default to false
      model: component === 2 ? selectedCategoryCompare : selectedCategory,
      platform:
        component === 2
          ? selectedModelCompare === 'claudeai'
            ? 'claude'
            : selectedModelCompare
          : selectedModel === 'claudeai'
            ? 'claude'
            : selectedModel,
      // platform: platform,
      system_message:
        component === 2
          ? replaceVariables(removeCommentBlocks(systemValueCompare), variablesCompare)
          : replaceVariables(removeCommentBlocks(systemValue), variables),
      temperature:
        component === 2
          ? parametersCompare[selectedModelCompare]?.temperature
          : parameters[selectedModel]?.temperature,
      maxTokens:
        component === 2
          ? parametersCompare[selectedModelCompare]?.maxTokens
          : parameters[selectedModel]?.maxTokens,
      topP:
        platform === 'openai' && component === 2
          ? parametersCompare[selectedModelCompare]?.topP
          : parameters[selectedModel]?.topP,
      frequencyPenalty:
        platform === 'openai' && component === 2
          ? parametersCompare[selectedModelCompare]?.frequencyPenalty
          : parameters[selectedModel]?.frequencyPenalty,
      presencePenalty:
        platform === 'openai' && component === 2
          ? parametersCompare[selectedModelCompare]?.presencePenalty
          : parameters[selectedModel]?.presencePenalty,

      ...(component === 1 &&
        selectedModel === 'openai' &&
        jsonMode &&
        !selectedCategory.includes('gpt-3.5-turbo') && {
          response_format: { type: 'json_schema', json_schema: JSON.parse(responseFormat) },
        }),
      ...(component === 2 &&
        selectedModelCompare === 'openai' &&
        jsonModeCompare &&
        !selectedCategoryCompare.includes('gpt-3.5-turbo') && {
          response_format: { type: 'json_schema', json_schema: JSON.parse(responseFormatCompare) },
        }),

      ...(component === 1 &&
        selectedModel === 'gemini' &&
        jsonMode && {
          response_format: JSON.parse(responseFormat),
        }),
      ...(component === 2 &&
        selectedModelCompare === 'gemini' &&
        jsonModeCompare && {
          response_format: JSON.parse(responseFormatCompare),
        }),

      versionId: selectedPromptVersion,
    }

    try {
      dispatch(
        chatWithModel({
          userData: payload,
          userId,
          meta: {
            onSuccess: (data) => {
              setChatHistory('assistant', data?.data?.response)
              SpeechRecognition.stopListening()
              resetTranscript()
              if (component === 1) {
                dispatch(setUserValue(''))
              }
              if (component === 2) {
                dispatch(setUserValueCompare(''))
              }
              dispatch(setAssistantValue(''))
              if (component === 1) {
                setRunLoading(false)
              }
              if (component === 2) {
                setRunLoadingCompare(false)
              }
            },
            onError: (error) => {
              if (error?.data?.status_code === 417) {
                configurationErrorAlert(selectedModel)
              } else {
                toast.error(error?.data?.message || 'An unexpected error occurred')
              }

              if (component === 1) {
                setRunLoading(false)
              }
              if (component === 2) {
                setRunLoadingCompare(false)
              }
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during fetch:', error)
      if (component === 1) {
        setRunLoading(false)
      }
      if (component === 2) {
        setRunLoadingCompare(false)
      }
    }
  }

  const handleOpenAttachmentMenu = (event) => {
    setAttachmentAnchor(event.currentTarget)
  }

  const handleCloseAttachmentMenu = () => {
    setAttachmentAnchor(null)
  }

  const handleUploadClick = () => {
    handleCloseAttachmentMenu()
    uploadImageRef.current.click()
  }

  const imageUploadFn = async (files) => {
    const formData = new FormData()
    Array.from(files).forEach((file) => {
      formData.append('files', file)
    })

    try {
      const response = await axiosInstance.post(uploadFileEndpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      // Axios automatically throws an error for non-2xx status codes,
      // so we don't need to check response.ok

      // Axios already parses the JSON, so we can directly use response.data
      return response.data.data.uploaded_files.map((file) => file.file_url)
    } catch (error) {
      console.error('Error uploading images:', error)
      // You might want to throw the error here instead of returning an empty array
      // to allow the calling function to handle the error
      throw new Error('Failed to upload images')
    }
  }

  const handleUploadImage = async (event) => {
    const files = event.target.files
    const maxSize = 5 * 1024 * 1024
    let validFiles = []

    try {
      validFiles = Array.from(files).filter((file) => {
        // Check file type
        const fileType = file.type.toLowerCase()
        if (!['image/jpeg', 'image/jpg', 'image/png'].includes(fileType)) {
          toast.warning(
            `File ${file.name} is not a supported image format. Supported formats are JPG, JPEG, and PNG.`,
          )
          return false
        }
        // Check file size
        if (file.size > maxSize) {
          toast.warning(`File ${file.name} exceeds the maximum size of 5MB.`)
          return false
        }
        return true
      })

      // Add loading placeholders
      const loadingPlaceholders = validFiles.map((file) => ({
        name: file.name,
        loading: true,
      }))
      setLoadingFiles((prev) => [...prev, ...loadingPlaceholders])

      const imageUrls = await imageUploadFn(validFiles)

      // Remove loading placeholders
      setLoadingFiles((prev) =>
        prev.filter((file) => !loadingPlaceholders.find((p) => p.name === file.name)),
      )

      if (imageUrls.length > 0) {
        setImagePreviews((prev) => [...prev, ...imageUrls])
      }
    } catch (error) {
      console.error('Error in image upload process:', error)
      // Remove loading placeholders on error
      setLoadingFiles((prev) =>
        prev.filter((file) => !validFiles.find((f) => f.name === file.name)),
      )
      toast.error('Failed to upload some images. Please try again.')
    } finally {
      event.target.value = ''
    }
  }

  const handleDeleteImage = (index) => {
    setImagePreviews((prev) => prev.filter((_, i) => i !== index))
  }

  useEffect(() => {
    if (selectedModel === 'claudeai') {
      setRole('user')
    }
  }, [selectedModel])

  const isVisionSupported = () => {
    const currentModel = component === 1 ? selectedCategory : selectedCategoryCompare
    return MODEL_FEATURES[currentModel]?.image || false
  }

  const handleOpenLinkDialog = () => {
    setOpenLinkDialog(true)
    handleCloseAttachmentMenu()
  }

  const handleCloseLinkDialog = () => {
    setOpenLinkDialog(false)
    setImageLink('')
    setLinkPreviewError('')
  }

  const handleImageLinkChange = (event) => {
    const url = event.target.value
    setImageLink(url)

    // Validate URL format
    if (url && !url.match(/^https?:\/\//)) {
      setLinkPreviewError('URL must start with http:// or https://')
    } else {
      setLinkPreviewError('')
    }
  }

  const validateImageLink = async (url) => {
    if (!url.match(/^https?:\/\//)) {
      return false
    }

    // Remove the @ symbol if present at the beginning of the URL
    url = url.replace(/^@/, '')

    try {
      // Use GET instead of HEAD, and set mode to 'no-cors'
      const response = await fetch(url, {
        method: 'GET',
        mode: 'no-cors',
      })

      // Since 'no-cors' mode doesn't allow access to response headers,
      // we'll assume it's an image if the fetch succeeds
      return true
    } catch (error) {
      console.error('Error validating image link:', error)
      return false
    }
  }

  const handleAddImageLink = async () => {
    if (!imageLink.match(/^https?:\/\//)) {
      setLinkPreviewError('URL must start with http:// or https://')
      return
    }

    if (await validateImageLink(imageLink)) {
      setImagePreviews((prevPreviews) => [...prevPreviews, imageLink])
      handleCloseLinkDialog()
    } else {
      setLinkPreviewError('Not a valid image link')
    }
  }

  const handleUploadAudioClick = () => {
    handleCloseAttachmentMenu()
    uploadAudioRef.current.click()
  }

  const handleUploadAudio = async (event) => {
    const files = event.target.files
    const maxSize = 10 * 1024 * 1024
    let validFiles = []

    try {
      validFiles = Array.from(files).filter((file) => {
        const fileType = file.type.toLowerCase()
        if (!['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/vnd.wave'].includes(fileType)) {
          toast.warning(
            `File ${file.name} is not a supported audio format. Supported formats are MP3, WAV, and OGG.`,
          )
          return false
        }
        if (file.size > maxSize) {
          toast.warning(`File ${file.name} exceeds the maximum size of 10MB.`)
          return false
        }
        return true
      })

      // Add loading placeholders
      const loadingPlaceholders = validFiles.map((file) => ({
        name: file.name,
        loading: true,
        type: 'audio',
      }))
      setLoadingFiles((prev) => [...prev, ...loadingPlaceholders])

      const audioUrls = await imageUploadFn(validFiles)

      // Remove loading placeholders
      setLoadingFiles((prev) =>
        prev.filter((file) => !loadingPlaceholders.find((p) => p.name === file.name)),
      )

      if (audioUrls.length > 0) {
        const newAudioPreviews = audioUrls.map((url, index) => ({
          url,
          name: validFiles[index].name,
          size: formatFileSize(validFiles[index].size),
          duration: 'Loading...',
        }))
        setAudioPreviews((prev) => [...prev, ...newAudioPreviews])
      }
    } catch (error) {
      console.error('Error in audio upload process:', error)
      setLoadingFiles((prev) =>
        prev.filter((file) => !validFiles.find((f) => f.name === file.name)),
      )
      toast.error('Failed to upload some audio files. Please try again.')
    } finally {
      event.target.value = ''
    }
  }

  const handleDeleteAudio = (index) => {
    setAudioPreviews((prev) => prev.filter((_, i) => i !== index))
    if (playingAudio === index) {
      setPlayingAudio(null)
    }
  }

  const handlePlayPauseAudio = (index) => {
    const audio = audioRefs.current[index]
    if (!audio) return

    if (playingAudio === index) {
      if (audio.paused) {
        audio.play()
      } else {
        audio.pause()
      }
    } else {
      if (playingAudio !== null) {
        audioRefs.current[playingAudio].pause()
      }
      audio.play()
      setPlayingAudio(index)
    }
  }

  const formatFileSize = (bytes) => {
    if (bytes < 1024) return bytes + ' B'
    else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + ' KB'
    else return (bytes / 1048576).toFixed(1) + ' MB'
  }

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.floor(seconds % 60)
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
  }

  useEffect(() => {
    audioPreviews.forEach((audio, index) => {
      if (audio.duration === 'Loading...') {
        const audioElement = new Audio(audio.url)
        audioElement.onloadedmetadata = () => {
          setAudioPreviews((prev) =>
            prev.map((a, i) =>
              i === index ? { ...a, duration: formatDuration(audioElement.duration) } : a,
            ),
          )
        }
      }
    })
  }, [audioPreviews])

  return (
    <MainBox>
      <UserPromptBox>
        <Stack direction="row" spacing={2} overflow={'auto'}>
          {/* Loading Files */}
          {loadingFiles.map((file, index) => (
            <div key={`loading-${index}`} style={{ position: 'relative', display: 'inline-block' }}>
              <Box
                sx={{
                  width: '128px',
                  height: '128px',
                  margin: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: Theme.palette.secondary.main,
                  borderRadius: '8px',
                }}
              >
                <CircularProgress size={40} />
              </Box>
              <Typography
                variant="caption"
                noWrap
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  color: Theme.palette.textColor,
                  mt: 1,
                }}
              >
                {file.name}
              </Typography>
            </div>
          ))}

          {/* Existing Image Previews */}
          {imagePreviews.map((image, index) => (
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <img
                key={index}
                src={image}
                alt={`Preview ${index}`}
                style={{
                  width: '128px',
                  height: '128px',
                  margin: '5px',
                  objectFit: 'contain',
                  padding: '8px',
                }}
              />
              <IconButton
                onClick={() => handleDeleteImage(index)}
                style={{ position: 'absolute', top: 4, right: 0 }}
              >
                <CloseIcon sx={{ fontSize: '16px' }} />
              </IconButton>
            </div>
          ))}
          <Stack direction="row" spacing={2} overflow={'auto'}>
            {audioPreviews.map((audio, index) => (
              <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                <audio
                  ref={(el) => (audioRefs.current[index] = el)}
                  src={audio.url}
                  onEnded={() => setPlayingAudio(null)}
                  style={{ display: 'none' }}
                />
                <Box
                  sx={{
                    width: '200px',
                    height: '128px',
                    margin: '5px',
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: Theme.palette.secondary.main,
                    borderRadius: '8px',
                  }}
                >
                  <Typography
                    variant="caption"
                    noWrap
                    sx={{ width: '100%', textAlign: 'center', color: Theme.palette.textColor }}
                  >
                    {audio.name}
                  </Typography>
                  <IconButton onClick={() => handlePlayPauseAudio(index)}>
                    {playingAudio === index && !audioRefs.current[index]?.paused ? (
                      <PauseIcon sx={{ color: Theme.palette.primary.main }} />
                    ) : (
                      <PlayArrowIcon sx={{ color: Theme.palette.primary.main }} />
                    )}
                  </IconButton>
                  <Typography variant="caption" sx={{ color: Theme.palette.textColor }}>
                    {audio.size} | {audio.duration}
                  </Typography>
                </Box>
                <IconButton
                  onClick={() => handleDeleteAudio(index)}
                  style={{ position: 'absolute', top: 4, right: 4 }}
                >
                  <CloseIcon sx={{ fontSize: '16px', color: Theme.palette.primary.main }} />
                </IconButton>
              </div>
            ))}
          </Stack>
        </Stack>
        <Box overflow="auto" py={0} display="flex" flexDirection="row">
          <UserTextField
            ref={inputRef}
            variant="outlined"
            value={
              role === 'assistant'
                ? component === 1
                  ? assistantValue
                  : assistantValueCompare
                : component === 1
                  ? userValue
                  : userValueCompare
            }
            disabled={component === 1 && isPublished}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'Enter') {
                // Prevent handleRun if already loading
                if (component === 1 && runLoading) return
                if (component === 2 && runLoadingCompare) return

                handleRun(component === 1 ? userValue : userValueCompare)
              }
            }}
            fullWidth
            multiline
            minRows={1}
            maxRows={4}
            placeholder={
              ready
                ? t(
                    `studio.studioInput.${role === 'assistant' ? 'assistantPlaceholder' : 'userPlaceholder'}`,
                  )
                : `Enter ${role === 'assistant' ? 'Assistant' : 'User'} message`
            }
            InputProps={{ sx: { borderRadius: 2, borderColor: Theme.palette.primary.main } }}
          />
        </Box>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Stack direction="row" px={1} gap={1}>
            {component === 1 && selectedModel !== 'claudeai' && (
              <CommonStyles.PrimaryButton
                ismobile={ismobile}
                variant="contained"
                onClick={handleRole}
                disabled={runLoading || isMicProcessing || isPublished} // Adjusted for component === 1
              >
                {ready
                  ? t(`studio.studioInput.${role === 'user' ? 'user' : 'assistance'}`)
                  : role === 'user'
                    ? 'User'
                    : 'Assistant'}
              </CommonStyles.PrimaryButton>
            )}

            {component === 2 && selectedModelCompare !== 'claudeai' && (
              <CommonStyles.PrimaryButton
                variant="contained"
                onClick={handleRole}
                disabled={runLoadingCompare} // Updated for component 2
                ismobile={ismobile}
              >
                {ready
                  ? t(`studio.studioInput.${role === 'user' ? 'user' : 'assistance'}`)
                  : role === 'user'
                    ? 'User'
                    : 'Assistant'}
              </CommonStyles.PrimaryButton>
            )}
            {role === 'user' && isVisionSupported() && (
              <Tooltip title="Attach File">
                <IconButton onClick={handleOpenAttachmentMenu}>
                  <AttachFileIcon />
                </IconButton>
              </Tooltip>
            )}
            <Menu
              anchorEl={attachmentAnchor}
              open={Boolean(attachmentAnchor)}
              onClose={handleCloseAttachmentMenu}
            >
              <MenuItem onClick={handleUploadClick}>Upload Image</MenuItem>
              {MODEL_FEATURES[component === 1 ? selectedCategory : selectedCategoryCompare]
                ?.audio && <MenuItem onClick={handleUploadAudioClick}>Upload Audio</MenuItem>}
              <MenuItem onClick={handleOpenLinkDialog}>Link to image</MenuItem>
            </Menu>
            {/* Input file for images */}
            <input
              multiple
              type="file"
              ref={uploadImageRef}
              accept="image/*"
              onChange={handleUploadImage}
              style={{ display: 'none' }}
            />
            {/* Input file for audio */}
            <input
              multiple
              type="file"
              ref={uploadAudioRef}
              accept="audio/*"
              onChange={handleUploadAudio}
              style={{ display: 'none' }}
            />
          </Stack>
          <Stack direction="row" px={1} gap={2}>
            {/* {component === 1 && !isPublished && (
              <SpeechToText
                role={role}
                setAssistantValue={setAssistantValue}
                isMicProcessing={isMicProcessing}
                setIsMicProcessing={setIsMicProcessing}
                runLoading={runLoading}
              />
            )} */}
            {component === 1 && selectedModel !== 'claudeai' && (
              <CommonStyles.PrimaryButton
                onClick={handleAdd}
                disabled={runLoading || isMicProcessing || (component === 1 && isPublished)}
                ismobile={ismobile}
              >
                {ready ? t('studio.studioInput.add') : 'ADD'}
              </CommonStyles.PrimaryButton>
            )}
            {component === 2 && selectedModelCompare !== 'claudeai' && (
              <CommonStyles.PrimaryButton
                ismobile={ismobile}
                onClick={handleAdd}
                disabled={runLoadingCompare} // Updated for component 2
              >
                {ready ? t('studio.studioInput.add') : 'ADD'}
              </CommonStyles.PrimaryButton>
            )}

            <CommonStyles.PrimaryButton
              ismobile={ismobile}
              onClick={() => handleRun(component === 1 ? userValue : userValueCompare)}
              disabled={
                (component === 1 ? runLoading : runLoadingCompare) || // Updated to check correct loading state
                role === 'assistant' ||
                isMicProcessing ||
                (component === 1 && isPublished)
              }
            >
              {ready ? t('studio.studioInput.run') : 'Run Ctrl'}
            </CommonStyles.PrimaryButton>
          </Stack>
        </Stack>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}></div>
      </UserPromptBox>

      {/* Image Link Dialog */}
      <StyledDialog open={openLinkDialog} onClose={handleCloseLinkDialog}>
        <DialogTitle>Add Image Link</DialogTitle>
        <StyledDialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Image URL"
            type="url"
            fullWidth
            variant="outlined"
            value={imageLink}
            onChange={handleImageLinkChange}
            error={!!linkPreviewError}
            helperText={linkPreviewError}
            placeholder="https://example.com/image.jpg"
          />
          {imageLink && !linkPreviewError && (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <img
                src={imageLink}
                alt="Preview"
                style={{
                  maxWidth: '100%',
                  maxHeight: '200px',
                  objectFit: 'contain',
                  borderRadius: '8px',
                }}
                onError={() => setLinkPreviewError('Not a valid image link')}
              />
            </Box>
          )}
        </StyledDialogContent>
        <DialogActions>
          <CommonStyles.SecondaryButton onClick={handleCloseLinkDialog}>
            Cancel
          </CommonStyles.SecondaryButton>
          <CommonStyles.PrimaryButton onClick={handleAddImageLink} disabled={!!linkPreviewError}>
            Add
          </CommonStyles.PrimaryButton>
        </DialogActions>
      </StyledDialog>
    </MainBox>
  )
}
